html {
  scroll-behavior: smooth; /* Enables smooth scrolling */
}

body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-headerContact {
  min-height: 100vh;
  background-color: rgb(248, 249, 250); /* Background color for contact page */
}

.hover-logo {
  position: relative; /* Allows positioning for the underline */
  overflow: hidden; /* Hides any overflow */
  border-radius: 10px; /* Slightly rounded corners */
  padding: 20px; /* Add padding for visual consistency */
  transition: transform 0.3s ease; /* Smooth scaling transition */
  cursor: default; /* Set cursor to default */
  border: 1px solid #fff; /* Set the border to white */
  color: #fff;
}

.hover-logo h3 {
  position: relative; /* Position relative to allow absolute positioning for the underline */
  display: inline-block; /* Aligns the underline with the text */
  margin-bottom: 10px; /* Space between text and underline */
  border-color: #fff;

}

.hover-logo h3::after {
  content: ''; /* Create an underline effect */
  position: absolute; /* Position it absolutely under the text */
  left: 0; /* Align to the left */
  bottom: 0; /* Align to the bottom of the text */
  height: 2px; /* Height of the underline */
  width: 100%; /* Full width of the text */
  background-color: rgb(178, 178, 178); /* Color of the underline */
  transform: scaleX(0); /* Start hidden */
  transition: transform 0.3s ease; /* Smooth transition for appearance */
  border-color: #fff;

}

/* Button with no hover effects */
.no-hover {
  background-color: transparent; /* Always transparent background */
  border: 2px solid white;       /* White border */
  color: white;                  /* White text */
  text-decoration: none;         /* No underline */
  transition: none;              /* No hover transition effects */
}

.no-hover:hover,
.no-hover:focus,
.no-hover:active {
  background-color: transparent; /* No background change on hover or click */
  border: 2px solid white;       /* Border remains white */
  color: white;                  /* Text remains white */
  outline: none;                 /* Remove focus outline */
}


.hover-logo:hover h3::after {
  transform: scaleX(1); /* Scale to full width on hover */
  border-color: #fff;

}

.hover-logo:hover {
  transform: scale(1.001); /* Scale effect on hover */
  cursor: default; /* Set cursor to default */
  border-color: #fff;

}

@media (max-width: 767px) {
  .hover-logo {
    min-height: 100px; /* Adjust as needed for mobile */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    border-color: #fff;

  }
}

.fixed-size {
  width: 200px; /* Set fixed width */
  height: 100px; /* Set fixed height */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-background-footer {
  position: relative; /* Position for pseudo-elements */
  overflow: hidden; /* Prevent overflow of animated elements */
}

/* Base styles for the app background */
.app-background {
  position: relative; /* Position for pseudo-elements */
  overflow: hidden; /* Prevent overflow of animated elements */
  min-height: 100vh; /* Ensure it covers the full height */
}

.app-background-footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #000000, #666666, #a29d9d, #000000);
  background-size: 400% 400%; /* Set the size for animation */
  animation: gradientAnimation 15s ease infinite; /* Animation for the background */
  z-index: -1; /* Behind other content */
}

/* Animated gradient background */
.app-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #000000, #666666, #a29d9d, #000000);
  background-size: 400% 400%; /* Set the size for animation */
  animation: gradientAnimation 15s ease infinite; /* Animation for the background */
  z-index: -1; /* Behind other content */
}

/* Keyframes for the gradient animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Override default focus styles for input and textarea elements within the contact form */
.bg-white input:focus, 
.bg-white textarea:focus {
  outline: none; /* Remove the default outline */
  box-shadow: 0 0 0 2px rgba(105, 105, 105, 0.5); /* Add a dark grey shadow */
  border-color: darkgrey; /* Change the border color to dark grey */
}

/* .cursor-dot {
  position: fixed;
  pointer-events: none; 
  transition: transform 0.1s ease; 
} */


/* snake button start*/

.animated-button span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, white, transparent);
  animation: animate1 2s linear infinite;
}

@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animated-button span:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(to bottom, white, transparent);
  animation: animate2 2s linear infinite;
  animation-delay: 0.5s;
}

@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

.animated-button span:nth-child(3) {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, white, transparent);
  animation: animate3 2s linear infinite;
}

@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animated-button span:nth-child(4) {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(to top, white, transparent);
  animation: animate4 2s linear infinite;
  animation-delay: 0.5s;
}

@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}


/* Snake button end */

/* Home page 360 */

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-n {
  display: inline-block;  /* Ensures it rotates properly */
  animation: rotate360 1s ease-in-out;
}


/* Home page 360 effect */

/* DNg logo css start  */

/* .hover-outline {
  position: relative;
  display: inline-block;
  text-decoration: none; 
}

.hover-outline:hover {
  color: white; 
  text-shadow: 
    1px 1px 0px #000, 
    -1px -1px 0px #000,
    1px -1px 0px #000,
    -1px 1px 0px #000; 
} */

/* DNg logo css end  */

/* Spinner start */



/* Spinner end */